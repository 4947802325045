<template>
  <v-dialog v-model="ModalAdd" :width="CWidth">
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            color="primary"
            fixed
            bottom
            right
            fab
            v-bind="attrs"
            v-on="{ ...tooltip, ...modal }"
            @click="openModal()"
          >
            <v-icon>mdi-bullhorn-outline</v-icon>
          </v-btn>
        </template>
        <span>Buat Pengumuman</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Tambah Pengumuman</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="ModalAdd = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="mx-7 mt-5">
          <v-row>
            <!-- Jenis Pengumuman -->
            <v-col cols="12" md="12" class="mb-n8">
              <span class="subtitle-2">Jenis Pengumuman</span>
              <v-select
                :items="jenisP"
                item-value="id"
                item-text="nama"
                dense
                flat
                outlined
                class="mt-2"
                v-model="pengumuman_jenis"
                autocomplete="off"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <!-- Judul Pengumuman -->
            <v-col cols="12" md="12" class="mb-n8">
              <span class="subtitle-2">Judul Pengumuman</span>
              <v-text-field
                dense
                flat
                outlined
                class="mt-2"
                v-model="pengumuman_judul"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- Isi pengumuman -->
            <v-col cols="12" class="mb-3">
              <editor
                ref="toastuiEditor"
                :initialValue="editorText"
                :options="editorOptions"
                height="500px"
                initialEditType="wysiwyg"
                previewStyle="vertical"
                @change="getHTML()"
              />
            </v-col>
          </v-row>

          <hr />

          <div class="text-right mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="add()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getRef.js";

import refreshView from "@/store/informasi/pengumuman/viewPengumuman";

import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/vue-editor";

export default {
  components: {
    editor: Editor,
  },

  created() {
    if (
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
    ) {
      this.CWidth = "100%";
    }
  },

  data: () => ({
    session: "",
    CWidth: "60%",
    ModalAdd: false,
    btnLoading: true,

    editorText: "Tulis Pengumuman Anda Disini ...",
    editorOptions: {
      hideModeSwitch: true,
      toolbarItems: [
        ["heading", "bold", "italic", "strike"],
        ["hr", "quote"],
        ["ul", "ol", "task", "indent", "outdent"],
        ["table", "link"],
        ["code", "codeblock"],
      ],
    },

    jenisP: [
      {
        id: "penting",
        nama: "Penting",
      },
      {
        id: "normal",
        nama: "Normal",
      },
    ],

    pengumuman_judul: "",
    pengumuman_isi: "",
    pengumuman_jenis: "",
  }),

  methods: {
    async openModal() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.refUserlvl = await getRef.Userlvl();
      this.ModalAdd = true;
    },

    getHTML() {
      this.pengumuman_isi = this.$refs.toastuiEditor.invoke("getHTML");
    },

    async add() {
      this.btnLoading = false;

      const data = {
        pengumuman_judul: this.pengumuman_judul,
        pengumuman_isi: this.pengumuman_isi,
        pengumuman_jenis: this.pengumuman_jenis,
      };

      console.log(data);

      const url = process.env.VUE_APP_API_BASE + "pengumuman";
      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.btnLoading = true;
          this.closeModal();
        });
    },

    closeModal() {
      this.ModalAdd = false;
    },
  },
};
</script>

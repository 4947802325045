<template>
  <v-dialog v-model="modalView" :width="CWidth">
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Detail Pengumuman</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="mx-7 mt-5">
          <v-row>
            <!-- Jenis Pengumuman -->
            <v-col cols="12" md="12" class="mb-n8">
              <span class="subtitle-2">Jenis Pengumuman</span>
              <v-select
                :items="jenisP"
                item-value="id"
                item-text="nama"
                dense
                flat
                outlined
                class="mt-2"
                v-model="viewItem.pengumuman_jenis"
                autocomplete="off"
                readonly
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <!-- Judul pengumuman -->
            <v-col cols="12" md="12" class="mb-n8">
              <span class="subtitle-2">Judul pengumuman</span>
              <v-text-field
                dense
                flat
                outlined
                class="mt-2"
                v-model="viewItem.pengumuman_judul"
                autocomplete="off"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- Isi pengumuman -->
            <v-col cols="12">
              <v-card
                outlined
                class="pa-5"
                v-html="viewItem.pengumuman_isi"
              ></v-card>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";

import modalView from "@/store/informasi/pengumuman/modalView";

export default {
  computed: {
    modalView: {
      get() {
        return modalView.state.modalView;
      },
      set(value) {
        modalView.commit("toggleModal", value);
      },
    },

    viewItem: {
      get() {
        return modalView.state.pengumuman;
      },
      set(value) {
        console.log(value);
      },
    },
  },

  watch: {
    async modalView() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    },
  },

  created() {
    if (
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
    ) {
      this.CWidth = "100%";
    }
  },

  data: () => ({
    session: "",
    CWidth: "60%",

    jenisP: [
      {
        id: "penting",
        nama: "Penting",
      },
      {
        id: "normal",
        nama: "Normal",
      },
    ],
  }),

  methods: {
    getHTML() {
      this.viewItem.pengumuman_isi = this.$refs.toastuiEditor.invoke("getHTML");
    },

    closeModal() {
      this.modalView = false;
    },
  },
};
</script>

<template>
  <div>
    <div>
      <v-card outlined class="py-3 px-5 mb-5 elevation-2">
        <v-row no-gutters>
          <h3 class="my-auto">Daftar Pengumuman</h3>
          <v-spacer></v-spacer>
        </v-row>
      </v-card>
    </div>

    <div>
      <CModalAdd />
      <CModalEdit />
      <CModalView />
      <CModalDelete />
    </div>

    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <div class="mb-10">
      <v-row>
        <v-col cols="4" v-for="item in pengumumans" :key="item.pengumuman_id">
          <v-card
            v-if="item.pengumuman_jenis === 'penting'"
            class="mb-5 white--text"
            min-height="300"
            color="error"
          >
            <v-card-title class="caption">
              <div>
                <v-icon small left color="white">mdi-clock-outline</v-icon>
                <span class="white--text">
                  {{ tglFormat(item.pengumuman_created_at) }}
                </span>
              </div>

              <v-spacer></v-spacer>

              <div class="text-right">
                <v-btn x-small text fab color="white" @click="hapus(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn x-small text fab color="white" @click="view(item)">
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
                <v-btn x-small text fab color="white" @click="edit(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </v-card-title>

            <v-divider class="mx-3 mt-n3 mb-3"></v-divider>

            <div class="px-3">
              <h2>{{ item.pengumuman_judul }}</h2>
            </div>

            <v-card-text class="white--text">
              <span v-html="item.pengumuman_isi.slice(0, 350) + '...'"></span>
            </v-card-text>
          </v-card>

          <v-card
            v-else
            class="mb-5"
            min-height="300"
          >
            <v-card-title class="caption">
              <div>
                <v-icon small left>mdi-clock-outline</v-icon>
                <span class="text--disabled">
                  {{ tglFormat(item.pengumuman_created_at) }}
                </span>
              </div>

              <v-spacer></v-spacer>

              <div class="text-right">
                <v-btn x-small text fab @click="hapus(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn x-small text fab @click="view(item)">
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
                <v-btn x-small text fab @click="edit(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </v-card-title>

            <v-divider class="mx-3 mt-n3 mb-3"></v-divider>

            <div class="px-3">
              <h2>{{ item.pengumuman_judul }}</h2>
            </div>

            <v-card-text>
              <span v-html="item.pengumuman_isi.slice(0, 350) + '...'"></span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <div class="mt-5">
        <v-pagination v-model="page" :length="totalRows"></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

import CModalAdd from "@/components/Admin/informasi/pengumuman/modalAdd";
import CModalEdit from "@/components/Admin/informasi/pengumuman/modalEdit";
import CModalDelete from "@/components/Admin/informasi/pengumuman/modalDelete";
import CModalView from "@/components/Admin/informasi/pengumuman/modalView";

import modalView from "@/store/informasi/pengumuman/modalView";
import modalEdit from "@/store/informasi/pengumuman/modalEdit";
import modalHapus from "@/store/informasi/pengumuman/modalHapus";
import refreshView from "@/store/informasi/pengumuman/viewPengumuman";

export default {
  components: {
    CModalAdd,
    CModalEdit,
    CModalDelete,
    CModalView,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },
      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },
      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },
      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },

    page() {
      this.getData();
      window.scrollTo(0, 0);
    },
  },

  data: () => ({
    session: "",

    page: 0,
    totalRows: 0,

    pengumumans: [],
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.getData();
  },

  methods: {
    tglFormat(tgl) {
      const date = new Date(tgl);
      const dateTimeFormat = new Intl.DateTimeFormat("id", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      });
      const formatted = dateTimeFormat.formatToParts(date);
      return (
        formatted[0].value + " " + formatted[2].value + " " + formatted[4].value
      );
    },

    getData() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "pengumuman?page=" + this.page, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((res) => {
          refreshView.commit("refreshData", false);
          this.pengumumans = res.data.data;
          this.page = res.data.page_number;
          this.totalRows = res.data.page_count;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    edit(value) {
      modalEdit.commit("toggleModal", true);
      modalEdit.commit("viewModal", Object.assign({}, value));
    },

    view(value) {
      modalView.commit("toggleModal", true);
      modalView.commit("viewModal", Object.assign({}, value));
    },

    hapus(value) {
      modalHapus.commit("toggleModal", true);
      modalHapus.commit("viewModal", Object.assign({}, value));
    },
  },
};
</script>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modalView: false,
        pengumuman: {
            pengumuman_id: '',
            pengumuman_judul: '',
            pengumuman_slug: '',
            pengumuman_jenis: '',
            pengumuman_isi: '',
            pengumuman_created_at: ''
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.modalView = value
        },
        viewModal(state, value) {
            state.pengumuman = value
        }
    }
})